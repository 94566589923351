import { render, staticRenderFns } from "./BranchFormData.vue?vue&type=template&id=cbb79e3c"
import script from "./BranchFormData.vue?vue&type=script&lang=js"
export * from "./BranchFormData.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports